import React from "react";
import { useTranslation } from "react-i18next";
import ContactFormCheckboxItem from "./ContactFormCheckboxItem";
import ContactFormSubmitButton from "./ContactFormSubmitButton";
import ContactFormTextInput from "./ContactFormTextInput";

const ContactFormStep01 = ({ formData, setFormData, loading, heading, subHeading }) => {

    const { t } = useTranslation();

    return (
        <div className={`${formData.formStep === 1 ? 'contactform-step-01-ctive' : 'contactform-step-01-inactive'} contactform-step max-width-5 mx-auto px3 z2`}>
            <h2 className="has-text-align-left h1 has-black-color has-text-color mb3">{heading}</h2>
            <p className="has-text-align-left has-black-color has-text-color mb2">{subHeading}</p>
            <p className="p bold mb3">{t('lemon_contactform_step_01_subhead')}</p>
            
            <div className={`contactform-inputs-wrapper ${loading || formData.formStep === 2 ? 'contactform-inputs-wrapper-inactive' : ''}`}>
                <div className="contactform-checkboxitems-wrapper mb3">
                    <ContactFormCheckboxItem tabIndex={formData.formStep === 1 ? 0 : -1} label={t('lemon_contactform_step_01_checkboxitems_option_label_meetup')} formData={formData} setFormData={setFormData} value={'meetup'} />
                    <ContactFormCheckboxItem tabIndex={formData.formStep === 1 ? 0 : -1} label={t('lemon_contactform_step_01_checkboxitems_option_label_presentation')} formData={formData} setFormData={setFormData} value={'presentation'} />
                    <ContactFormCheckboxItem tabIndex={formData.formStep === 1 ? 0 : -1} label={t('lemon_contactform_step_01_checkboxitems_option_label_demoversion')} formData={formData} setFormData={setFormData} value={'demoversion'} />
                </div>
                <ContactFormTextInput tabIndex={formData.formStep === 1 ? 0 : -1} placeholder={t('lemon_contactform_step_01_textinputs_placeholder_lastname')} type="text" required={true} setFormData={setFormData} value={'lastname'} />
                <ContactFormTextInput tabIndex={formData.formStep === 1 ? 0 : -1} placeholder={t('lemon_contactform_step_01_textinputs_placeholder_email')} type="email" required={true} setFormData={setFormData} value={'email'} />
                {formData.meetup && formData.formStep === 1 && <ContactFormTextInput tabIndex={formData.formStep === 1 ? 0 : -1} placeholder={t('lemon_contactform_step_01_textinputs_placeholder_phone')} type="phone" required={false} setFormData={setFormData} value={'phone'} />}
            </div>
            <div className="contactform-footer flex flex-wrap justify-between items-center mt2">
                <div className="col-12 lg-col-6">
                    <p className="p bold mb1">{t('lemon_contactform_step_01_footer_required_fields_declaration_heading')}</p>
                    <p className="contactform-p-small col-12" style={{lineHeight: 1.4}}>{t('lemon_contactform_step_01_footer_required_fields_declaration_paragraph')}</p>
                </div>
                <ContactFormSubmitButton tabIndex={formData.formStep === 1 ? 0 : -1} label={t('lemon_contactform_step_01_submit_button_label')} loading={loading} />
            </div>
        </div>
    );
}

export default ContactFormStep01