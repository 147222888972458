import React from "react";

const ContactFormStep03 = ({formData, setFormData, loading, heading, subHeading }) => {

    return (
        <div className={`${formData.formStep === 3 ? 'contactform-step-03-ctive' : 'contactform-step-03-inactive'} contactform-step max-width-5 mx-auto px3 col-12`}>
            <div className="contactform-step-03-success-icon mb3"></div>
            <h2 className="has-text-align-left h1 has-black-color has-text-color mb3">{heading}</h2>
            <p  className="has-text-align-left has-black-color has-text-color mb2 h4" style={{maxWidth: '700px', lineHeight: 1.33}} dangerouslySetInnerHTML={{__html: subHeading}} />
        </div>
    );
}

export default ContactFormStep03