import React from "react";

const ContactFormSpinner = ({show}) => {
    return (
        <svg 
            className={`contactform-spinner ${show ? 'show-contactform-spinner' : ''}`}
            height="20" 
            width="20" 
            viewBox="0 0 20 20"
        >
            <circle 
                r="10" 
                cx="10" 
                cy="10" 
                fill="white" 
            />
            <circle 
                r="8" 
                cx="10" 
                cy="10" 
                fill="black"
                stroke="black"
                strokeWidth="12"
                strokeDasharray="calc(50 * 31.42 / 100) 50"
            />
        </svg>
    );
}

export default ContactFormSpinner