const lang_en = {
    lemon_contactform_step_01_heading: "Get started now! Learn more about Lemon® and the benefits of our mobile e-learning system.",
    lemon_contactform_step_01_paragraph: "Tell us how you would like to get in touch with us. The choice is yours: we'll be happy to call you, send you a presentation as a PDF, show you Lemon® in a live demo or give you our free 14-day trial version directly to your smartphone or tablet – without any obligation, of course.",
    lemon_contactform_step_01_subhead: "I would like",
    lemon_contactform_step_01_checkboxitems_option_label_meetup: "non-binding appointment",
    lemon_contactform_step_01_checkboxitems_option_label_presentation: "a product-presentation",
    lemon_contactform_step_01_checkboxitems_option_label_demoversion: "free 14-day test-version",
    lemon_contactform_step_01_textinputs_placeholder_lastname: "Lastname*",
    lemon_contactform_step_01_textinputs_placeholder_email: "E-Mail address*",
    lemon_contactform_step_01_textinputs_placeholder_phone: "Phonenumber",
    lemon_contactform_step_01_footer_required_fields_declaration_heading: "Fields marked with * are mandatory.",
    lemon_contactform_step_01_footer_required_fields_declaration_paragraph: "I can revoke my consent to the processing of personal data at any time with effect for the future.",
    lemon_contactform_step_01_submit_button_label: "Submit",
    lemon_contactform_step_02_data_sent_success_notification: "Thank you for enquiry.",
    lemon_contactform_step_02_heading: "We have one more small question for you.",
    lemon_contactform_step_02_paragraph: "It would help us if you would state for which application you intend to use emon® E-Learning.",
    lemon_contactform_step_02_submit_button_label: "Submit",
    lemon_contactform_step_02_skip_button_label: "Skip",
    lemon_contactform_step_02_checkboxitems_option_label_application_education_employees: "Education of employees",
    lemon_contactform_step_02_checkboxitems_option_label_application_franchise_solutions: "Franchise solutions",
    lemon_contactform_step_02_checkboxitems_option_label_application_training: "Academy/Training",
    lemon_contactform_step_02_checkboxitems_option_label_application_knowledge_managemenent: "Customer knowledge management",
    lemon_contactform_step_02_checkboxitems_option_label_application_sales_support: "Sales support",
    lemon_contactform_step_03_heading: "Data sent successfully.",
    lemon_contactform_step_03_paragraph: "Thank you for your interest in our product. We will process your enquiry at our earliest convenience.<br><br>Kind regards<br>Your Lemon® Team.",
    lemon_contactform_step_01_submit_alert_select_mandatory: "Please select one or more options of what we should deliver.",
    lemon_contactform_step_02_submit_alert_select_mandatory: "Please select one or more options as reason for your interest in our services."
};

export default lang_en;