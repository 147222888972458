import React from "react";
import { useTranslation } from "react-i18next";
import ContactFormSubmitButton from "./ContactFormSubmitButton";
import ContactFormCheckBoxInput from './ContactFormCheckboxInput'


const ContactFormStep02 = ({formData, setFormData, loading, heading, subHeading }) => {

    const { t } = useTranslation();

    const skipStep02 = () => {
        setFormData(prevState => {
            return {...prevState, formStep: 3};
        });
    }

    return (
        <div className={`${formData.formStep === 2 ? 'contactform-step-02-ctive' : formData.formStep === 3 ? 'contactform-step-02-inactive-02' : 'contactform-step-02-inactive'} contactform-step max-width-5 mx-auto px3 col-12 border-box`}>
            <h3 className="mb1 flex items-center"><span className="contactform-success-message-icon"></span>{t('lemon_contactform_step_02_data_sent_success_notification')}</h3>
            <h2 className="has-text-align-left h1 has-black-color has-text-color mb2">{heading}</h2>
            <p className="has-text-align-left has-black-color has-text-color mb3">{subHeading}</p>
            <div className={`contactform-inputs-wrapper mb3 ${loading || formData.formStep === 1 ? 'contactform-inputs-wrapper-inactive' : ''}`}>
                <div className="mb3">
                    <ContactFormCheckBoxInput tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_checkboxitems_option_label_application_education_employees')} name="checkboxitenms_education_employees" value={'application_employee_education'} setFormData={setFormData} />
                    <ContactFormCheckBoxInput tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_checkboxitems_option_label_application_franchise_solutions')} name="checkboxitenms_franchise_solutions" value={'application_franchise_solutions'} setFormData={setFormData}   />
                    <ContactFormCheckBoxInput tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_checkboxitems_option_label_application_training')} name="checkboxitenms__training" value={'application_training'} setFormData={setFormData}     />
                    <ContactFormCheckBoxInput tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_checkboxitems_option_label_application_knowledge_managemenent')} name="checkboxitenms_customer_knowledge_management" value={'application_customer_knowledge_management'} setFormData={setFormData}   />
                    <ContactFormCheckBoxInput tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_checkboxitems_option_label_application_sales_support')} name="checkboxitenms_sales_support" value={'application_sales_support'} setFormData={setFormData}   />
                </div>
            </div>
            <div className="contactform-step-02-seperator"></div>
            <div className="flex justify-end" style={{gap: '1rem'}}>
            <button onClick={skipStep02} type="submit" className={`contact-form-skip-button ${loading ? 'contactform-skip-button-inactive' : ''} contactform-submit-button`}>{t('lemon_contactform_step_02_skip_button_label')}</button>
            <ContactFormSubmitButton tabIndex={formData.formStep === 2 ? 0 : -1} label={t('lemon_contactform_step_02_submit_button_label')} loading={loading}/>
            </div>
        </div>
    );
}

export default ContactFormStep02