import React from "react";

const ContactFormCheckboxItem = ({label, setFormData, value, formData, tabIndex}) => {
    const toggleValue = (e) => {
        e.preventDefault()
        setFormData(prevState => {
            return {...prevState, [value]: !prevState[value]};
        });
    }
    return (
        <button tabIndex={tabIndex} className={`contactform-checkboxitem ${formData[value] ? 'contactform-checkboxitem-active' : ''}`} onClick={toggleValue}>{label}</button>
    );
}

export default ContactFormCheckboxItem