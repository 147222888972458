import React from "react";

const ContactFormCheckboxInput = ({placeholder, required, setFormData, value, tabIndex, name, label}) => {
    const handleChange = (e) => {
        setFormData(prevState => {
            return {...prevState, [value]: e.target.checked};
        });
    }
    return (
        <div className="contactform-checkboxinput-wrapper flex items-center mb2">
        <input 
            id={name}
            name={name}
            tabIndex={tabIndex}
            className="contactform-checkboxinput border-box" 
            type={'checkbox'} 
            placeholder={placeholder} 
            required={required} 
            onChange={handleChange}
        />
        <label htmlFor={name}>
            {label}
        </label>
        </div>
    );
}

export default ContactFormCheckboxInput