import React from "react";

const ContactFormTextInput = ({type, placeholder, required, setFormData, value, tabIndex}) => {
    const handleChange = (e) => {
        setFormData(prevState => {
            return {...prevState, [value]: e.target.value};
        });
    }
    return (
        <input 
            tabIndex={tabIndex}
            className="contactform-textinput mb2 col-12 border-box" 
            type={type} 
            placeholder={placeholder} 
            required={required} 
            onChange={handleChange}
        />
    );
}

export default ContactFormTextInput