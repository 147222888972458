import React, { useState, useRef } from "react";
import axios from "axios";
import ContactFormStep01 from "./ContactFormStep01";
import ContactFormStep02 from "./ContactFormStep02";
import ContactFormStep03 from "./ContactFormStep03";
import { useTranslation } from "react-i18next";

const ContactForm = ({ root }) => {

    const { t } = useTranslation();

    const contactForm = useRef(null);

    const [formData, setFormData] = useState({
        formStep: 1,
        lastname: '',
        email: '',
        phone: '',
        meetup: false,
        presentation: false,
        demoversion: false,
        application_employee_education: false,
        application_franchise_solutions: false,
        application_training: false,
        application_customer_knowledge_management: false,
        application_sales_support: false,
        url: window.location.href
    });

    const [loading, setLoading] = useState(false);

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const checkBoxItemsValid = () => {
        let clickedBoxes = 0;
        if (formData.meetup) { clickedBoxes++ };
        if (formData.presentation) { clickedBoxes++ };
        if (formData.demoversion) { clickedBoxes++ };
        if (clickedBoxes > 0) {
            return true;
        } else {
            return false;
        }
    }

    const step_02_checkBoxItemsValid = () => {
        let clickedBoxes = 0;
        if (formData.application_employee_education) { clickedBoxes++ };
        if (formData.application_franchise_solutions) { clickedBoxes++ };
        if (formData.application_training) { clickedBoxes++ };
        if (formData.application_customer_knowledge_management) { clickedBoxes++ };
        if (formData.application_sales_support) { clickedBoxes++ };
        if (clickedBoxes > 0) {
            return true;
        } else {
            return false;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.formStep === 1) {
            if (!checkBoxItemsValid()) {
                window.alert(t('lemon_contactform_step_01_submit_alert_select_mandatory'));
                return;
            }

            setLoading(true);

            axios.post(' https://mailer.lemon-mobile-learning.com/mailer-api/', formData)
                .then(response => {
                    setFormData(prevState => {
                        return { ...prevState, formStep: 2 };
                    });
                    const element = contactForm;
                    setLoading(false);
                    try {
                        if (window.gtag) {
                            window.gtag('event', 'Formular ausgefüllt', {
                                'event_category': 'Interaktion',
                                'event_label': 'Formular ausgefüllt (Step 01)',
                                'value': 1
                            });
                            window.gtag('event', 'conversion', {'send_to': 'AW-708300871/Ns-cCJD81JkYEMeg39EC'});
                        }
                    }
                    catch (error) {
                        console.log("GA Tracker Error => ", error);
                    }
                    setTimeout(() => {
                        if (!isInViewport(element.current)) {
                            const y = element.current.getBoundingClientRect().top + window.scrollY;
                            window.scroll({
                                top: y,
                                behavior: 'smooth'
                            });
                        }
                    }, 200);
                })
                .catch(err => {
                    console.log(err);
                })
        }
        if (formData.formStep === 2) {
            if (!step_02_checkBoxItemsValid()) {
                window.alert(t('lemon_contactform_step_02_submit_alert_select_mandatory'));
                return;
            }
            setLoading(true);
            axios.post(' https://mailer.lemon-mobile-learning.com/mailer-api/', formData)
                .then(response => {
                    setFormData(prevState => {
                        return { ...prevState, formStep: 3 };
                    });
                    const element = contactForm;
                    setLoading(false);
                    try {
                        if (window.gtag) {
                            console.log('sending info to google.');
                            window.gtag('event', 'Formular ausgefüllt', {
                                'event_category': 'Interaktion',
                                'event_label': 'Formular ausgefüllt (Step 02)',
                                'value': 1
                            });
                            window.gtag('event', 'conversion', {'send_to': 'AW-708300871/Ns-cCJD81JkYEMeg39EC'});
                        }
                    }
                    catch (error) {
                        console.log("GA Tracker Error => ", error);
                    }
                    setTimeout(() => {
                        if (!isInViewport(element.current)) {
                            const y = element.current.getBoundingClientRect().top + window.scrollY;
                            window.scroll({
                                top: y,
                                behavior: 'smooth'
                            });
                        }
                    }, 200);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }
return (
    <form  
        ref={contactForm} 
        onSubmit={handleSubmit} 
        className="contactform-wrapper mx-auto pt4 pb4 bg-color1 relative z2"
    >
        <ContactFormStep01 
            formData={formData} 
            setFormData={setFormData} 
            loading={loading} 
            heading={root.getAttribute('data-heading-step-01') !== '' ? root.getAttribute('data-heading-step-01') : t('lemon_contactform_step_01_heading')} 
            subHeading={root.getAttribute('data-subheading-step-01') !== '' ? root.getAttribute('data-subheading-step-01') : t('lemon_contactform_step_01_paragraph')} 
        />
        <ContactFormStep02 
            formData={formData} 
            setFormData={setFormData} 
            loading={loading} 
            heading={root.getAttribute('data-heading-step-02') !== '' ? root.getAttribute('data-heading-step-02') : t('lemon_contactform_step_01_heading')} 
            subHeading={root.getAttribute('data-subheading-step-02') !== '' ? root.getAttribute('data-subheading-step-02') : t('lemon_contactform_step_02_paragraph')} 
        />
        <ContactFormStep03 
            formData={formData} 
            setFormData={setFormData} 
            loading={loading} 
            heading={t('lemon_contactform_step_03_heading')} subHeading={t('lemon_contactform_step_03_paragraph')} 
        />
    </form>
);
}

export default ContactForm