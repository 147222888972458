import React from "react";
import ContactFormSpinner from "./ContactFormSpinner";

const ContactFormSubmitButton = ({label, loading, tabIndex}) => {
    return (
        <button tabIndex={tabIndex} type="submit" className="contactform-submit-button">
            <span className={`contactform-submit-button-label ${loading ? 'hide-contactform-submit-button-label' : ''}`}>{label}</span>
            <ContactFormSpinner show={loading}/>
        </button>
    );
}

export default ContactFormSubmitButton