const lang_de = {
    lemon_contactform_step_01_heading: "Jetzt direkt loslegen! Erfahren Sie mehr über Lemon® und die Vorteile unseres mobilen E-Learning-Systems.",
    lemon_contactform_step_01_paragraph: "Sie haben die Wahl: Wir senden Ihnen gern eine kurze Präsentation als PDF, zeigen Ihnen Lemon® in einer Live-Demo oder stellen Ihnen unsere kostenlose 14-tägige Testversion auf Ihrem Smartphone oder Tablet zur Verfügung – selbstverständlich vollkommen unverbindlich.",
    lemon_contactform_step_01_subhead: "Ich möchte",
    lemon_contactform_step_01_checkboxitems_option_label_meetup: "einen unverbindlichen Gesprächstermin",
    lemon_contactform_step_01_checkboxitems_option_label_presentation: "eine Produktpräsentation",
    lemon_contactform_step_01_checkboxitems_option_label_demoversion: "eine kostenlose 14-tägige Testversion",
    lemon_contactform_step_01_textinputs_placeholder_lastname: "Nachname*",
    lemon_contactform_step_01_textinputs_placeholder_email: "E-Mail Adresse*",
    lemon_contactform_step_01_textinputs_placeholder_phone: "Telefonnummer",
    lemon_contactform_step_01_footer_required_fields_declaration_heading: "Mit * markierte Felder sind sind Pflichfelder",
    lemon_contactform_step_01_footer_required_fields_declaration_paragraph: "Meine Einwilligung in die Verarbeitung der personenbezogenen Daten kann ich jederzeit mit Wirkung für die Zukunft widerrufen.",
    lemon_contactform_step_01_submit_button_label: "Absenden",
    lemon_contactform_step_02_data_sent_success_notification: "Vieln Dank für Ihre Anfrage!",
    lemon_contactform_step_02_heading: "Wir haben noch eine kleie Frage an Sie.",
    lemon_contactform_step_02_paragraph: "Wir wollen unsere Produkte stets optimieren! Daher würde es uns helfen, wenn Sie angeben, wofür sie Lemon E-Learning benutzen wollen. Vielen Dank!",
    lemon_contactform_step_02_submit_button_label: "Absenden",
    lemon_contactform_step_02_skip_button_label: "Überspringen",
    lemon_contactform_step_02_checkboxitems_option_label_application_education_employees: "Weiterbildung der Mitarbeiter",
    lemon_contactform_step_02_checkboxitems_option_label_application_franchise_solutions: "Franchise-Lösungen",
    lemon_contactform_step_02_checkboxitems_option_label_application_training: "Akademie/Ausbildung",
    lemon_contactform_step_02_checkboxitems_option_label_application_knowledge_managemenent: "Wissensmanagement für Kunden",
    lemon_contactform_step_02_checkboxitems_option_label_application_sales_support: "Sales Support",
    lemon_contactform_step_03_heading: "Daten erfolgreich versendet.",
    lemon_contactform_step_03_paragraph: "Wir bedanken uns für Ihr Interesse an unseren Produkten und werden Ihre Anfrage umgehend bearbeiten.<br><br> Freundliche Grüße,<br> Ihr Lemon® Team.",
    lemon_contactform_step_01_submit_alert_select_mandatory: "Bitte wählen Sie eine oder mehrere Optionen für Ihre Kontaktaufnahme.",
    lemon_contactform_step_02_submit_alert_select_mandatory: "Bitte wählen Sie eine oder mehrere Optionen als Grund für Ihre Kontaktaufnahme."
};

export default lang_de;