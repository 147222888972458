import './App.css';
import './ContactFormStyles.css'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ContactForm from './ContactForm';
import lang_de from './translations/de';
import lang_en from './translations/en';

const root = document.getElementById('root');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: lang_en
      },
      de: {
        translation: lang_de
      }
    },
    lng: root.getAttribute('data-lang'), // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  const root = document.getElementById('root');
  return (
    <div className="App">
      <ContactForm root={root}/>
    </div>
  );
}

export default App;
